import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import __c from "../utilities/combineClasses"
import Layout from "../components/layout"
import SEO from "../components/seo"

import style from "./consulting.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    {
      vis: file(name: { eq: "consulting--custom-visualization" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      test: file(name: { eq: "consulting--message-testing" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
      buy: file(name: { eq: "consulting--media-buying" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            maxWidth: 584
            layout: FLUID
            quality: 100
            background: "#46128a"
            placeholder: DOMINANT_COLOR
          )
        }
      }
    }
  `)
  return (
    <Layout className={style.gridLayout}>
      <SEO title="Consulting Services" />
      <article className={__c(style.article)}>
        <header className={__c(style.header)}>
          <h1 className={__c(style.pageTitle)}>Consulting services</h1>
          <p className={__c(style.introduction)}>
            Varia, Inc has partnered with D2 Seattle, experts at managing media
            buys, designing ad creative, testing messaging concepts, and
            insightful reporting.
          </p>
        </header>
        <main className={__c(style.main)}>
          <section className={__c([style.section, style.sectionVis])}>
            <h2 className={__c(style.sectionHeading)}>Custom visualization</h2>
            <div className={__c(style.sectionIllustration)}>
              <GatsbyImage
                alt="Stylized Venn diagram visualization of client market landscape"
                image={data.vis.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className={__c(style.sectionDescription)}>
              <p>
                Take VariaMetrix data to the next level by working with
                D2&apos;s graphic designers who help you illustrate your
                marketing story and business strategies in your brand style.
              </p>
            </div>
          </section>
          <section className={__c([style.section, style.sectionTesting])}>
            <h2 className={__c(style.sectionHeading)}>Media testing</h2>
            <div className={__c(style.sectionIllustration)}>
              <GatsbyImage
                alt="Collection of ads D2 tested for UW Foster School of Business"
                image={data.test.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className={__c(style.sectionDescription)}>
              <p>
                VariaMetrix can tell you down to a detailed level how your
                multivariate or A/B tests are performing, but if you need
                assistance setting those up, D2 is ready to help.
              </p>
            </div>
          </section>
          <section className={__c([style.section, style.sectionBuying])}>
            <h2 className={__c(style.sectionHeading)}>Media Buying</h2>
            <div className={__c(style.sectionIllustration)}>
              <GatsbyImage
                alt="Purple box"
                image={data.buy.childImageSharp.gatsbyImageData}
              />
            </div>
            <div className={__c(style.sectionDescription)}>
              <p>
                D2 Seattle plans, negotiates, implements, and measures ad buys
                across all channels and platforms. They have managed over US$100 million
                in media buys over 15 years across markets worldwide.
              </p>
            </div>
          </section>
          <section className={__c(style.signUpButtonContainer)}>
            <a href="//d2seattle.com/service/analytics" className={__c(`main-button`)}>Learn more at d2seattle.com</a>
          </section>
        </main>
      </article>
    </Layout>
  )
}
